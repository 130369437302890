"use client";

import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

export const useRedirectTo = () => {
  const searchParams = useSearchParams();

  const redirectToURL = useMemo(() => {
    if (typeof window !== "undefined") {
      const next = searchParams.get("next");

      return `${window.location.origin}/auth/callback${next ? `?next=${encodeURIComponent(next)}` : ""}`;
    }

    return undefined;
  }, [searchParams]);

  return redirectToURL;
};
