"use client";

import { Button } from "@kamio/design-system/components/ui/button";
import { useState } from "react";
import { toast } from "sonner";

import { useRedirectTo } from "@/hooks/use-redirect-to";
import { createClient } from "@/lib/supabase/client";

export const GoogleButton = () => {
  const [clicked, setClicked] = useState(false);

  const supabase = createClient();
  const redirectTo = useRedirectTo();

  return (
    <Button
      variant="outline"
      className="w-full flex items-center justify-center space-x-2"
      onClick={async () => {
        setClicked(true);

        const { error } = await supabase.auth.signInWithOAuth({
          provider: "google",
          options: {
            queryParams: {
              access_type: "offline",
              prompt: "consent",
            },
            redirectTo: redirectTo,
          },
        });

        if (error) {
          toast.error(error.message);
        }
      }}
      disabled={clicked}
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24">
        <path
          d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
          fill="#4285F4"
        />
        <path
          d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
          fill="#34A853"
        />
        <path
          d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
          fill="#FBBC05"
        />
        <path
          d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
          fill="#EA4335"
        />
        <path d="M1 1h22v22H1z" fill="none" />
      </svg>
      <span>Sign in with Google</span>
    </Button>
  );
};

export const AzureButton = () => {
  const [clicked, setClicked] = useState(false);

  const supabase = createClient();
  const redirectTo = useRedirectTo();

  return (
    <Button
      variant="outline"
      className="w-full flex items-center justify-center space-x-2"
      onClick={async () => {
        setClicked(true);

        const { error } = await supabase.auth.signInWithOAuth({
          provider: "azure",
          options: {
            queryParams: {
              scopes: "email offline_access",
            },
            redirectTo: redirectTo,
          },
        });

        if (error) {
          toast.error(error.message);
        }
      }}
      disabled={clicked}
    >
      <svg width="150" height="150" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            id="e399c19f-b68f-429d-b176-18c2117ff73c"
            x1="-1032.172"
            x2="-1059.213"
            y1="145.312"
            y2="65.426"
            gradientTransform="matrix(1 0 0 -1 1075 158)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#114a8b" />
            <stop offset="1" stopColor="#0669bc" />
          </linearGradient>
          <linearGradient
            id="ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15"
            x1="-1023.725"
            x2="-1029.98"
            y1="108.083"
            y2="105.968"
            gradientTransform="matrix(1 0 0 -1 1075 158)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopOpacity=".3" />
            <stop offset=".071" stopOpacity=".2" />
            <stop offset=".321" stopOpacity=".1" />
            <stop offset=".623" stopOpacity=".05" />
            <stop offset="1" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="a7fee970-a784-4bb1-af8d-63d18e5f7db9"
            x1="-1027.165"
            x2="-997.482"
            y1="147.642"
            y2="68.561"
            gradientTransform="matrix(1 0 0 -1 1075 158)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#3ccbf4" />
            <stop offset="1" stopColor="#2892df" />
          </linearGradient>
        </defs>
        <path
          fill="url(#e399c19f-b68f-429d-b176-18c2117ff73c)"
          d="M33.338 6.544h26.038l-27.03 80.087a4.152 4.152 0 0 1-3.933 2.824H8.149a4.145 4.145 0 0 1-3.928-5.47L29.404 9.368a4.152 4.152 0 0 1 3.934-2.825z"
        />
        <path
          fill="#0078d4"
          d="M71.175 60.261h-41.29a1.911 1.911 0 0 0-1.305 3.309l26.532 24.764a4.171 4.171 0 0 0 2.846 1.121h23.38z"
        />
        <path
          fill="url(#ac2a6fc2-ca48-4327-9a3c-d4dcc3256e15)"
          d="M33.338 6.544a4.118 4.118 0 0 0-3.943 2.879L4.252 83.917a4.14 4.14 0 0 0 3.908 5.538h20.787a4.443 4.443 0 0 0 3.41-2.9l5.014-14.777 17.91 16.705a4.237 4.237 0 0 0 2.666.972H81.24L71.024 60.261l-29.781.007L59.47 6.544z"
        />
        <path
          fill="url(#a7fee970-a784-4bb1-af8d-63d18e5f7db9)"
          d="M66.595 9.364a4.145 4.145 0 0 0-3.928-2.82H33.648a4.146 4.146 0 0 1 3.928 2.82l25.184 74.62a4.146 4.146 0 0 1-3.928 5.472h29.02a4.146 4.146 0 0 0 3.927-5.472z"
        />
      </svg>
      <span>Sign in with Azure</span>
    </Button>
  );
};
